import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import MarkdownRenderer from './components/MarkdownRenderer';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setAccessToken(storedToken);
    } else {
      getAccessToken();
    }
  }, []);

  const getAccessToken = async () => {
    try {
      const response = await fetch('https://api.openaitool.cn/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
        }),
      });
      if (!response.ok) {
        throw new Error('获取访问令牌失败');
      }
      const data = await response.json();
      const newToken = data.access_token;
      setAccessToken(newToken);
      localStorage.setItem('accessToken', newToken);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchBlogPost = async () => {
    try {
      const response = await fetch(`https://api.openaitool.cn/posts/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('accessToken');
          getAccessToken();
          return;
        }
        throw new Error('获取博客文章失败');
      }
      const data = await response.json();
      setPost(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchBlogPost();
    }
  }, [accessToken, id]);

  if (loading) return <p>加载中...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!post) return <p>未找到文章</p>;

  return (
    <div className="relative max-w-4xl mx-auto px-4 bg-white">
      <article className="py-8 bg-white">
        <Link to="/" className="text-blue-600 hover:underline mb-4 inline-block">
          &larr; 返回首页
        </Link>
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        <time className="text-sm text-gray-500 mb-8 block">
          {new Date(post.created_at).toLocaleDateString('zh-CN')}
        </time>
        <div className="prose prose-lg dark:prose-invert max-w-none bg-white">
          <MarkdownRenderer 
            content={post.content}
            darkMode={false}
          />
        </div>
      </article>
    </div>
  );
};

export default BlogPost;
