// components/GoogleAnalytics.js
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const GoogleAnalytics = ({ GA_MEASUREMENT_ID }) => {
  const location = useLocation()

  useEffect(() => {
    // 加载 GA 脚本
    const loadGAScript = () => {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`
      script.async = true
      document.head.appendChild(script)

      // 声明全局变量
      window.dataLayer = window.dataLayer || []
      function gtag() {
        // @ts-ignore
        window.dataLayer.push(arguments)
      }
      // 将 gtag 添加到 window 对象
      window.gtag = gtag

      window.gtag('js', new Date())
      window.gtag('config', GA_MEASUREMENT_ID)
    }

    loadGAScript()

    // 监听路由变化
    const handleRouteChange = () => {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname + location.search,
      })
    }

    // 初始页面加载时触发
    handleRouteChange()
  }, [location, GA_MEASUREMENT_ID])

  return null
}

export default GoogleAnalytics