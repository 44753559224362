import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import BlogPost from './BlogPost';
import GoogleAnalytics from './components/GoogleAnalytics'

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const BlogPostList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setAccessToken(storedToken);
    } else {
      getAccessToken();
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchBlogPosts();
    }
  }, [accessToken]);

  const getAccessToken = async () => {
    try {
      const response = await fetch('https://api.openaitool.cn/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
        }),
      });
      if (!response.ok) {
        throw new Error('获取访问令牌失败');
      }
      const data = await response.json();
      const newToken = data.access_token;
      setAccessToken(newToken);
      localStorage.setItem('accessToken', newToken);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchBlogPosts = async () => {
    try {
      const response = await fetch('https://api.openaitool.cn/posts', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('accessToken');
          getAccessToken();
          return;
        }
        throw new Error('获取博客列表失败');
      }
      const data = await response.json();
      const sortedPosts = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setBlogPosts(sortedPosts);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const stripMarkdown = (text) => {
    if (!text) return '';
    return text
      .replace(/^#+\s+/gm, '')
      .replace(/[*_]{1,2}([^*_]+)[*_]{1,2}/g, '$1')
      .replace(/```[\s\S]*?```/g, '')
      .replace(/`([^`]+)`/g, '$1')
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
      .replace(/!\[([^\]]*)\]\([^\)]+\)/g, '')
      .replace(/^\s*>\s+/gm, '')
      .replace(/^[-*+]\s+/gm, '')
      .replace(/^\d+\.\s+/gm, '')
      .replace(/- \[ \]/g, '')
      .replace(/- \[x\]/g, '')
      .replace(/\n{3,}/g, '\n\n')
      .trim();
  };

  return (
    <Router>
      <div className="min-h-screen bg-white">
        <GoogleAnalytics GA_MEASUREMENT_ID={GA_MEASUREMENT_ID} />
        <div className="max-w-3xl mx-auto p-4 font-sans">
          <header className="flex items-center space-x-4 mb-16">
            <div className="w-8 h-8 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 text-blue-500">
                <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
                <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
                <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
              </svg>
            </div>
            <h1 className="text-2xl font-bold">老彭的分享</h1>
            <span className="text-sm text-gray-500">博客</span>
            <span className="text-sm text-gray-500">翻译</span>
          </header>

          <Routes>
            <Route path="/" element={
              <main>
                <h2 className="text-4xl font-bold mb-8">博客</h2>
                <div className="border-b border-gray-200 mb-4"></div>
                {loading ? (
                  <p>加载中...</p>
                ) : error ? (
                  <p className="text-red-500">{error}</p>
                ) : (
                  blogPosts.map((post) => (
                    <article key={post.id} className="mb-3 bg-white py-2">
                      <Link 
                        to={`/post/${post.id}`} 
                        className="text-lg font-bold hover:text-blue-600 block bg-white"
                      >
                        {stripMarkdown(post.title)}
                      </Link>
                      <p className="text-gray-600 text-sm mt-0.5 mb-0.5 bg-white">
                        {stripMarkdown(post.content).substring(0, 100)}...
                      </p>
                      <time className="text-xs text-gray-500 bg-white">
                        {new Date(post.created_at).toLocaleDateString('zh-CN')}
                      </time>
                    </article>
                  ))
                )}
              </main>
            } />
            <Route path="/post/:id" element={<BlogPost />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default BlogPostList;
