import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypePrismPlus from 'rehype-prism-plus';
import remarkEmoji from 'remark-emoji';
import { Copy, Check } from 'lucide-react';
import 'katex/dist/katex.min.css';
import 'prismjs/themes/prism-tomorrow.css';

const MarkdownRenderer = ({ content, darkMode = false }) => {
  // 图片组件
  const ImageComponent = ({ src, alt, ...props }) => (
    <div className="my-6 rounded-lg overflow-hidden shadow-md">
      <img
        src={src}
        alt={alt}
        loading="lazy"
        className="w-full h-auto"
        {...props}
      />
    </div>
  );

  // 代码块组件
  const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const [copied, setCopied] = useState(false);
    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : '';
    
    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    };

    if (inline) {
      return (
        <code className="px-1 py-0.5 mx-1 rounded bg-gray-100 text-pink-600 text-sm font-mono" {...props}>
          {children}
        </code>
      );
    }

    return (
      <div className="my-6 rounded-lg overflow-hidden bg-[#2A2A2A] group relative border border-gray-700">
        <div className="flex items-center justify-between h-8 px-4 bg-[#1E1E1E] text-xs border-b border-gray-700">
          <span className="text-gray-400 font-mono">{language}</span>
          <button
            onClick={() => copyToClipboard(String(children).trim())}
            className="text-gray-400 hover:text-white transition-colors"
            title={copied ? "Copied!" : "Copy code"}
          >
            {copied ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
          </button>
        </div>
        <div className="relative">
          <pre 
            className="p-4 overflow-x-auto text-gray-200 font-['JetBrains_Mono',_'Fira_Code',_'SF_Mono',_Consolas,_monospace] text-[14px] leading-[1.6] tracking-wide"
            style={{
              backgroundColor: '#2A2A2A',
              tabSize: 2,
            }}
            {...props}
          >
            {children}
          </pre>
        </div>
      </div>
    );
  };

  // 表格组件
  const TableComponent = ({ children, ...props }) => (
    <div className="my-6 overflow-x-auto">
      <table className="w-full border-collapse" {...props}>
        {children}
      </table>
    </div>
  );

  // 标题组件
  const createHeading = (level) => {
    const styles = {
      1: "text-3xl",
      2: "text-2xl",
      3: "text-xl",
      4: "text-lg",
      5: "text-base",
      6: "text-sm"
    };

    return ({ children, ...props }) => {
      const Tag = `h${level}`;
      return (
        <Tag 
          className={`font-bold text-gray-900 my-6 ${styles[level]}`}
          {...props}
        >
          {children}
        </Tag>
      );
    };
  };

  return (
    <div className={`max-w-4xl mx-auto px-4 py-8 ${darkMode ? 'dark' : ''}`}>
      <article className="prose prose-gray max-w-none">
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkMath, remarkEmoji]}
          rehypePlugins={[
            rehypeKatex,
            [rehypePrismPlus, { ignoreMissing: true, showLineNumbers: false }],
          ]}
          components={{
            img: ImageComponent,
            code: CodeBlock,
            table: TableComponent,
            h1: createHeading(1),
            h2: createHeading(2),
            h3: createHeading(3),
            h4: createHeading(4),
            h5: createHeading(5),
            h6: createHeading(6),
            p: ({ children }) => (
              <p className="my-4 leading-7 text-gray-700">{children}</p>
            ),
            a: ({ children, href }) => (
              <a 
                href={href} 
                className="text-blue-600 hover:text-blue-800 underline decoration-blue-300 hover:decoration-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </a>
            ),
            ul: ({ children }) => (
              <ul className="list-disc pl-6 my-4 space-y-2">{children}</ul>
            ),
            ol: ({ children }) => (
              <ol className="list-decimal pl-6 my-4 space-y-2">{children}</ol>
            ),
            li: ({ node, checked, children, ...props }) => {
              if (typeof checked === 'boolean') {
                return (
                  <li className="flex items-start space-x-2" {...props}>
                    <input
                      type="checkbox"
                      checked={checked}
                      readOnly
                      className="mt-1"
                    />
                    <span>{children}</span>
                  </li>
                );
              }
              return <li className="text-gray-700" {...props}>{children}</li>;
            },
            blockquote: ({ children }) => (
              <blockquote className="border-l-4 border-gray-200 pl-4 my-4 italic text-gray-600">
                {children}
              </blockquote>
            ),
            hr: () => <hr className="my-8 border-gray-200" />,
          }}
        >
          {content}
        </ReactMarkdown>
      </article>
    </div>
  );
};

export default MarkdownRenderer;